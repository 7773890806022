.CompactCardAction {
  display: flex;
  flex: 1;
  height: 1rem !important;
  border-radius: 0.7rem;
  position: relative;
  cursor: pointer;
  color: white;
  padding: 1rem;
  margin-bottom: 0px;
}
.CompactCardAction:hover {
  box-shadow: none !important;
}

.CompactCardAction:not(:last-child) {
  margin-right: 5px !important; /* Agregar espacio horizontal de 5px entre los elementos */
}
.CardContainerAction {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  gap: 1rem;
  align-items: center !important;
  justify-content: center !important;
}

/*expandible card*/
.ExpandedCardAction {
  position: absolute;
  width: 60%;
  height: 70vh;
  z-index: 9;
  left: 13rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-around;
  padding: 1rem;
  top: 5rem; 
  
}

.ExpandedCardAction > span:nth-of-type(1) {
  font-size: 26px;
  font-weight: bold;
  color: white;
  text-shadow: 0px 0px 15px white;
}

.ExpandedCardAction > span:nth-of-type(2) {
  color: rgb(223, 222, 222);
  font-size: 16px;
}

.ChartContainer {
  width: 70%;
  height: auto;
}



@media screen and (max-width: 1200px) {
  .ExpandedCardAction {
    top: 2rem;
    height: 70vh;
    left: 6rem;
  }
  .CompactCard {
    margin-bottom: 0% !important;
    height: auto !important;
  }
}

@media screen and (max-width: 880px) {

  .CompactCardAction{
   gap: 5rem !important;
  }
}

@media screen and (max-width: 760px) {
  .ExpandedCardAction {
    top: 8rem;
    height: 50%;
    left: 15px;
    width: 80%;
  }
  .CompactCardAction span {
    display: none !important;
  }
}
