.infoBotton2{
    font-weight: bolder;
    border-radius: 15px;
    padding: 0px ;
    cursor: pointer;
    
}

.modalDialog2{
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}