.Updates{
    width: 88%;
    height: 100%;
    background: white ;
    border-radius: 0.7rem;
    padding: 1rem;
    gap:0.5rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;    
}

.update{
    display:flex;
    gap: 0.5rem;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: left;
}

.update>img{
    width: 3.2rem;
    height: 3.2rem;


}

.noti>div>span:nth-of-type(1){
    font-weight: bold;
}