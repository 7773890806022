.auth-form-container {
  background-color: white;
  border-radius: 5%;
  box-shadow: 5px 5px;
  color: black;
  height: auto;
  width: 20rem;
  top: 0;
  margin-bottom: 0%;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.image {
  margin-bottom: 1rem;
}

.formLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 70%;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 1rem;
}
.input {
  margin-top:5px;
  font-size: 18px;
}

.input-wrong {
  font-size: 18px;
  border-color: #ff0000;
  outline: none;
}

.input:focus {
  border-color: #0000ff;
  outline: none;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.label {
  font-weight: bold;
  font-size: 18px;
}

.btn-login {
  width: 50%;
  background-color: #1e90ff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  margin-top: 0rem;
  margin-bottom: 2rem;
  font-weight: bold;
  cursor: pointer;
  color: #f2f2f2;
  text-decoration: none;
}

.btn-login:hover {
  background-color: #7cbcfc;
  cursor: pointer;
}

.footer {
  width: 100% !important;

  padding: 0.5px;
  background-color: #ffe6f4b8 !important;
  text-align: center;
  font-size: 10px;
}
.footer p {
  color: #000000b8;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .auth-form-container {
    margin-top: 20% !important;
    width: 90% !important;
    height: auto !important;
    border-radius: 6px !important;
  }
  .logo-container {
    margin-top: 0.5rem !important;
  }

  .image {
    width: 35% !important;
    height: auto !important;
    margin-bottom: 0%;
  }
}
