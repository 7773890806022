.FacturaInfo__header {
  display: flex;
  align-items: center;
  margin-left: 1%;
  margin-top: 1%;

}

.FacturaInfo {
  background-color: rgb(250, 246, 246);
  border-radius: 10px;
}


.FacturaInfo__info {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50%;
}

.factInfo {
  display: flex;
  flex-direction: column;
  margin: 0 2%;
  border-radius: 6px;
  padding: 3px;
}

.FacturaInfo__info__cliente {
  margin-top: 5%;
}

.FacturaInfo__info__cliente,
.FacturaInfo__info__direccion,
.FacturaInfo__info__telefono,
.FacturaInfo__info__duenio,
.FacturaInfo__info__barrio,
.FacturaInfo__info__fecha {
  flex-basis: 50%;
  margin-bottom: 0.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;


}

.FacturaInfo__table {
  z-index: 15;
}

.FacturaInfo__info__fecha {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.SelectCliente {
  width: 100%;
  height: 40px;
  border-radius: 50px;
}

.FacturaInfo__info__boton {
  height: 60%;
  background-color: #99ff33 !important;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 5px;
}

/* Estilos del botón flotante */
.boton-flotante {
  position: fixed;
  bottom: 30px;
  right: 60px;
  z-index: 100;
  font-weight: 500;
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  font-size: 17px;
  border: none;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/* Estilo al pasar el ratón por encima */
.boton-flotante:hover {
  background-color: #0056b3;
}

.select {
  width: 100%;
  height: 40px;
  border-radius: 50px;
  z-index: 8;
}