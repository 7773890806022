.Precios {
  display: flex !important;
  width: 100% !important;
  height: 90%;
  flex-direction: column;
  justify-content: first baseline;
  overflow-x: scroll;
}
.contenedorOpcionesPrecios {
  display: flex;
  width: 85%;
  height: 11%;
  flex-direction: row;
  justify-content:center;
  align-items:center;
  gap: 20px;

}
.opcion-Boton{
  display: flex;
  flex-direction: row;
  height: 100%;

}


.contenedorOpcionesPrecios Select{
  width: 200px !important;
  height: 100% !important;
  border-radius: 3px;

}

.button {
  height: 100%;
  background-color: #0f6fff !important;
  width: 200px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}

.button:hover {
  background-color: #00a1d6 !important;
}

.precioContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.precioEspecialContenedo {
  display: flex;
  flex-direction: column;
  
}

.containerTablefilter {
  display: flex;
  flex-direction: column;
}

.containerFormPrecioEspecial{
  display: flex;
  flex-direction: row;
  justify-content:left;
  align-items: center;
  padding-bottom: 15px;
  gap: 19%;
  margin-left: 1%;
}

@media screen and (max-width: 1200px) {
  .Precios {
    width: 120% !important;
  }
  .precioContainer {
    width: 100% !important;
  }
}

@media screen and (max-width: 880px) {
  .precioContainer {
    width: 100% !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .contenedorOpcionesPrecios{
    flex-direction: column !important;
    height: 100% !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .opcion-Boton{
    height: 40px !important;
  }
}

.Precios::-webkit-scrollbar {
  width: 0.5em; /* Ancho de la barra de desplazamiento */
}

.Precios::-webkit-scrollbar-track {
  background-color: transparent; /* Color de fondo de la barra de desplazamiento */
}
