
.Sidebar{
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 13%;
    z-index: 99;
    transition: all 300ms ease;
    
}

.logo{
    display: flex;
    height: 5rem;
    font-weight: bold;
    font-size:22px;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    height: 4%;

}

.logo img{
    width: 60px;
    height: auto;
}

.logo>img{
    width: 3em;
    height: 3em;
}

.logo>span{
    color:rgb(22, 121, 214);
}

.logo>span>span{
    color: rgb(203, 2, 2);
}

/* menu */

.menu{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.menuItem{
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2.5rem;
    padding-left: 2rem;
    position: relative;
    transition: all 300ms ease;
    font-size: 16px;
    border-radius: 0.7rem;
}

.menuItem:hover{
    cursor: pointer;
}

.active{
    background-color: rgba(68, 153, 233, 0.586);
    margin-left: 0;
    padding-left: .01rem;

}

.active::before{
    content: '';
    width: 8px;
    height: 100%;
    background-color: rgb(2, 25, 203);
    border-radius: 0.1rem;
 
 
}

.menu .menuItem:last-child{
    position: absolute ;
    bottom: 2.3rem;
    width: 100%;
}

.bars{
    display: none;
    position: fixed;
    top:2rem;
    z-index: 9 ;
    padding: 10px;
    border-radius: 10px;
}


@media screen and (max-width: 1200px){
    .menuItem>span{
        display: none;
    }
    .logo{
        display: none;
    }
}

@media screen and (max-width: 768px){
    .Sidebar{
        position: fixed;
        z-index: 9;
        background: #ffe0e0;
        width: 55%;
        padding-right: 1rem;
        height: 100%;
    }
    .menuItem>span{
        display: block;
    }
    .logo{
        display: flex;
    }

    .menu .menuItem:last-child{
        position: relative;
        margin-top: 2rem;
    }

    .bars{
        display: flex;
        position: fixed;
        top:2rem;
        z-index: 9 ;
        background: #ffe0e0;
        padding: 10px;
        border-radius: 5px;

    }
}
