.FormContainerEditar {
  background-color: rgba(255, 255, 255, 0.75) !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  height: 80%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  margin-top: 2%;
  padding: 15px;
}

.datosEditar {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  
}

.datosEditar > div {
  display: flex;
  flex-direction: column;
}

.datosEditar input {
   border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
}

.datosEditar span {
  width: 50%;
  font-size: large;
}

.selectNegocioEditar {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.581);
  margin-bottom: 8%;
  
}

.selectNegocioEditar button {
  padding: 8px 16px;
  margin-top: 0.5rem;
  background-color: #ff4d4d;
  width: 45%;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.selectNegocioEditar button:hover {
  background-color: #0bdeff !important;
}

.selectNegocioEditar select {
  width: 50%;
  height: 10%;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
}

.confirmation {
  width: 85%;
  height: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.confirmation p {
  text-align: center;
}

.checkeliminado {
  margin-top: 5%;
  color: green;
  font-size: 20px;
}

@media (max-width: 780px) {
  .FormContainerEditar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: white !important;
    display: flex;
    gap: 10px;
    width: 80%;
    height: 70%;
   

  }

  .selectNegocioEditar {
    width: 100%;


    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    margin-top: 85px !important;
    
  }
  .selectNegocioEditar select {
    width: 50%;
    height: 20% !important;
    border-radius: 10px;
    border: 1px solid #b74040;
    padding: 18px;
    cursor: pointer;
    margin-top: 3%;
    color: black !important;
  }
  
  .selectNegocioEditar button {
    padding: 6px 8px;
    margin-top: 0.2rem;
    background-color: #ff4d4d;
    width: 45%;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .datosEditar{
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
