.TableFilter{ 
    height: auto; 
    width: 100%;
    background-color: rgba(255, 255, 255, 0.838);
    
}

.MuiDataGrid-columnHeaderTitle{
    font-weight: bold !important;
}

