.Product{
    display: flex;
    width: 98%;
    height: 100%;
    flex-direction: column;
    overflow-x: scroll;

    
}

.title{
    margin-left: 1rem;
}

.Product::-webkit-scrollbar {
    width: 0.5em; /* Ancho de la barra de desplazamiento */
  }

.Product::-webkit-scrollbar-track {
    background-color: transparent; /* Color de fondo de la barra de desplazamiento */
  }


