.FormPrecioEspecialEditar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 90%;
  background-color: rgb(255, 255, 255);
  padding: 2%;
  border-radius: 15px;
  margin-top: 1%;

  overflow-y: scroll;
}

.selectcontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center !important;
  width: 100%;
  height: 100%;
}

.imagenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}
.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 90%;
  font-weight: bold;
  gap: 3px;
  padding: 5%;
}

.formContainer input {
  height: 25px;
  width: 80%;
}

.formContainer label {
  margin-top: 20px;
  width: 100% !important;
}
.formContainer select {
  height: 25px !important;
  width: 82% !important;
}

input[type="text"].readonly {
  background-color: #f2f2f2;
  cursor: not-allowed;
}

.formContainer button {
  width: 82%;
  height: 30px;
  background-color: rgb(97, 210, 56);
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 10px;
  padding: 5px;
}
.formContainer button:hover {
  background-color: rgb(182, 249, 158);
}
.empresa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left !important;
  width: 82%;
}
.opcionModificar {
  margin-bottom: 3%;
}
@media (max-width: 780px) {
  .imagenContainer {
    display: none;
  }
}
