.ClienteCards{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
}

.parentContainerCliente{
    display: flex;
    flex-direction: column;
    height:  100%;
}

