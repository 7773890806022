.FormPPF {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 80%;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(251, 251, 251, 0.944);
  border-radius: 15px;
  padding: 2%;
}

.subTitleContainer{
  width: 100%;
  padding-left: 25%;
  display: flex;
  justify-content:left;
  align-items:flex-start;
  text-align:left;
}

.FormPPFContainer {
  display: block;
  flex-direction: column;
  width: 50%;
  height: 100%;
  scroll-behavior: smooth;
  overflow-y: scroll; /* Cambiado de "scroll" a "auto" */
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content:left;
  align-items:flex-start;
  text-align:left;
}

.FormPPF select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80%;
  margin-bottom: 15px;
  margin-left: 2%;
}



.FormFields {
  display: flex;
  flex-direction: column;
 
}

.FormField select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 96%;
  margin-bottom: 5px;
}

.FormField {
  display: flex;
  flex-direction: column;
  align-items: center; /* Cambiado de "left" a "flex-start" */
  justify-content: center; /* Cambiado de "left" a "flex-start" */
}



.FormFields div {
  margin-bottom: 10px;
}

label {
  width: 95%;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 90%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.botonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.FormPPFContainer::-webkit-scrollbar {
  width: 0.001em; /* Ancho de la barra de desplazamiento */
  background-color: #f5f5f5; /* Color de fondo de la barra de desplazamiento */
}

.FormPPFContainer::-webkit-scrollbar-thumb {
  background-color: #888; /* Color del pulgar de la barra de desplazamiento */
  border-radius: 0.1px; /* Borde redondeado del pulgar de la barra de desplazamiento */
}

.FormPPFContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color del pulgar de la barra de desplazamiento al pasar el mouse sobre él */
}

@media (max-width: 780px){

  .FormPPF {
    width: 90%;
    height: 90%;
  }

  
}
