.Table{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.MuiTableRow-root>*{
  padding: 10px;
}

.MuiTableRow-root.MuiTableRow-head>*{
font-weight: bold;

}

.Table td,th{
    border: none !important;
}

.Table:first-child{
    border-radius: 0.7rem !important;
}

.status{
    padding: 8px;
    border-radius: 0.5rem;
}



.Details{
    color: blue !important;
}

.Details:hover{
    cursor: pointer;
}

