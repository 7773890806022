.FormContainerDelete {
  background-color: rgba(255, 255, 255, 0.75) !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  height: 70%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.mensaje {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.mensaje span {
  width: 50%;
  font-size: large;
}

.selectNegocio {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.581);
}

.selectNegocio button {
  padding: 8px 16px;
  margin-top: 0.5rem;
  background-color: #00b6f8 !important;
  width: 45%;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.selectNegocio button:hover {
  background-color: #ff4747 !important;
}

.selectNegocio select {
  width: 50%;
  height: 10%;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
}

.confirmation {
  width: 85%;
  height: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.confirmation p {
  text-align: center;
}

.checkeliminado {
  margin-top: 5%;
  color: green;
  font-size: 20px;
}

@media (max-width: 780px) {
  .mensaje {
    display: none !important;
  }

  .selectNegocio {
    width: 100%;
    justify-content: center;
    
  }

  .selectNegocio button {
    padding: 6px 8px;
    margin-top: 0.2rem;
    background-color: #0fcbff;
    width: 45%;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .selectNegocio select {
    height: 18%;

  }
}
