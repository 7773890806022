.form{
  background-color: #ffffffb5;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 0rem;
  border-radius: 10px;
  width: 60%;
  height: 70%;
  align-items: center;
  padding: 1rem;
  margin-top: 15px;
}
.myform {
   /* Color de fondo blanco */
   
 padding: 2.5rem 1rem 0rem 1rem;
  display: grid;
  gap: 0.5rem;
  width: 65%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem !important;
}

.myform label {
  display: block;
  margin-bottom: 8px;
  font-weight:bold ;
}

.input{
  width: 85%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
 .error{

  margin-bottom: 15px;
 }

.error input{
  border: 1px solid red !important;
}

.form button {
  padding: 8px 16px;
  background-color: #4caf50;
  width: 65%;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form button:hover {
  background-color: #18c805 !important;
}

.form span{
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

.form p{
  color: rgb(36, 245, 12);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

.form::-webkit-scrollbar {
  width: 0.5em; /* Ancho de la barra de desplazamiento */
  background-color: #f5f5f5; /* Color de fondo de la barra de desplazamiento */
}

.form::-webkit-scrollbar-thumb {
  background-color: #888; /* Color del pulgar de la barra de desplazamiento */
  border-radius: 1px; /* Borde redondeado del pulgar de la barra de desplazamiento */
}

.form::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color del pulgar de la barra de desplazamiento al pasar el mouse sobre él */
}

/* Media Query para 1 columna en resoluciones menores a 700px */
@media (max-width: 780px) {
  .myform {
    grid-template-columns: 1fr;
    padding: 2rem;
    gap: 0.3rem;
  }
  .myform label {
    display: block;
    margin-bottom: 8px;
  }

  .myform input,
  .myform select {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}
