.Analytics {
  background-color: rgb(255, 255, 255);
  padding: 0% 2%;
  overflow-y: scroll;
}

.botones {
  background-color: rgba(255, 235, 235, 0.507);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contenedorGrafico {
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  gap: 2%;
  max-height: 90%;
  justify-content:space-between ;
  flex-wrap: wrap;

}

.grafico {
  display: flex;
  flex-direction: column;
  background-color: rgb(233, 150, 150);
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 5px;
  width: 62%;
  height: 50%;
}

.tabla {
  width: 30%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(251, 234, 234);

  height: 50%;
}
@media (max-width: 1100px) {
  .grafico {
    width: 95%;
  }
  .contenedorGrafico {
    max-height: 90%;
    align-items:flex-start;
    justify-content: flex-start;
  }
  .tabla{
    width: 99%;
    margin-top: 2%;
  }
}

@media (max-width: 500px) {
  .grafico {
    width: 95%;
  }
  .contenedorGrafico {
    max-height: 90%;
    align-items:flex-start;
    justify-content: flex-start;
  }
  .tabla{
    width: 99.5%;
    margin-top: 2%;
  }
}
