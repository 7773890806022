.Clientes{
    display: flex;
    width: 97%;
    height: 100%;
    flex-direction: column;
    overflow-x: scroll;
}

.title{
    margin-left: 1rem;
}


.Clientes::-webkit-scrollbar {
    width: 0.5em; /* Ancho de la barra de desplazamiento */
  }

.Clientes::-webkit-scrollbar-track {
    background-color: transparent; /* Color de fondo de la barra de desplazamiento */
  }


