.infoBotton{
    font-weight: bolder;
    border-radius: 15px;
    background-color: rgb(194, 204, 204);
    padding: 0px ;
    cursor: pointer;
    
}

.modalDialog{
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}